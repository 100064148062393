import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

function SEO({ title, description, backgroundImage, url }) {
  const socialImage = null // todo make from background image
  return (
    <Helmet>
      <html lang="en" />

      <title>{title}</title>

      <meta name="description" content={description} />

      <meta
        name="google-site-verification"
        content="PGNZKz7saYGqlxUcONnvJrHGnYz6MIK3amJQi5zqQsU"
      />
      <meta
        name="google-site-verification"
        content="9YGnIV_LTBe472TS5xWGBqCfXPo_FHScKiTDl1suyIA"
      />

      <meta property="og:locale" content="en_GB" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content="Ned Poulter" />
      {socialImage !== null && (
        <meta property="og:image" content={socialImage} />
      )}
      {socialImage !== null && (
        <meta property="og:image:secure_url" content={socialImage} />
      )}
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="675" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:site" content="@nedpoulter" />
      {socialImage !== null && (
        <meta name="twitter:image" content={socialImage} />
      )}

      <meta name="twitter:creator" content="@nedpoulter" />

      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Fira+Sans:wght@700&family=Open+Sans:ital,wght@0,400;0,700;1,400&display=swap"
        rel="stylesheet"
      />
      <script>
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-W4FZXM');`}
      </script>
      <style>{`.portable-text a{text-decoration: underline; transition: color 0.15s ease-in-out} .portable-text a:hover{text-decoration: none; opacity: 0.75; color: #1e40af}`}</style>
    </Helmet>
  )
}

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  backgroundImage: PropTypes.object,
  url: PropTypes.string,
}
