import React, { useState } from 'react'
// import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import tw from 'twin.macro'
import { RoughNotation } from 'react-rough-notation'

import Menu from './Menu'

const HeaderWrap = tw.header`absolute z-30 w-full  p-4 text-white flex items-center justify-between flex-wrap`
const Title = tw.p`font-heading font-bold text-xl `

const headerOpen = tw`items-start`

const Navigation = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const { settings } = useStaticQuery(graphql`
    query Navigation {
      settings: sanitySiteSettings {
        headingMenu {
          items {
            _key
            title
            url {
              slug {
                current
              }
            }
          }
        }
      }
    }
  `)

  const { headingMenu } = settings

  return (
    <>
      <HeaderWrap css={[menuOpen ? headerOpen : '']}>
        <Title>
          <RoughNotation
            type="highlight"
            show
            animationDelay="200"
            animationDuration="400"
            color="#00000075"
          >
            <AniLink fade to="/">
              Ned Poulter
            </AniLink>
          </RoughNotation>
        </Title>
      </HeaderWrap>
      <Menu
        items={headingMenu.items}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
      />
    </>
  )
}

export default Navigation

Navigation.propTypes = {
  // data: PropTypes.object,
}
