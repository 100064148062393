import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import tw from 'twin.macro'

const HeaderWrap = tw.section`fixed h-screen w-screen overflow-hidden z-0`
const LogoWrap = tw.div`absolute inset-0 flex items-center justify-center h-screen-3/4`

function HeaderImage({ title, backgroundImage, logo }) {
  return (
    <HeaderWrap>
      {backgroundImage?.asset && (
        <GatsbyImage
          className="w-full h-screen"
          style={{
            height: '100vh',
            width: '100vw',
          }}
          placeholderStyle={{
            height: '100vh',
            width: '100vw',
          }}
          imgStyle={{
            pointerEvents: 'none',
            position: 'absolute',
            width: '100vw',
            height: '100vh',
            zIndex: '-1',
            objectFit: 'cover',
          }}
          alt={backgroundImage.asset.altText}
          image={backgroundImage.asset.gatsbyImageData}
        />
      )}
      {logo?.asset && (
        <LogoWrap>
          <GatsbyImage
            className="w-full max-w-md"
            alt={logo.asset.altText}
            image={logo.asset.gatsbyImageData}
          />
        </LogoWrap>
      )}
    </HeaderWrap>
  )
}

export default HeaderImage

HeaderImage.propTypes = {
  title: PropTypes.string,
  backgroundImage: PropTypes.object,
  logo: PropTypes.object,
}
