import React from 'react'
import PropTypes from 'prop-types'
import tw from 'twin.macro'
import BasePortableText from '@sanity/block-content-to-react'

import serializers from '../Serializers'

import Item from '../../atoms/Item'
import OuterWrapper from './OuterWrapper'
import Buttons from '../Buttons'

const Section = tw.section`md:max-w-screen-md mx-auto lg:max-w-screen-xl`
const Title = tw.h2`font-heading py-4 font-bold text-xl md:text-2xl lg:text-4xl`
const ContentWrap = tw.article`px-4 py-8 md:py-12`
const ItemWrap = tw.div`flex flex-wrap -mx-4`

function ItemsBlock({
  content: { title, _rawBody, items, buttonAlignment, buttons },
  index,
}) {
  let hasButtons = false

  if (buttons) hasButtons = true
  if (buttons === []) hasButtons = false
  if (buttons?.length === 0) hasButtons = false

  return (
    <OuterWrapper index={index}>
      <Section>
        <ContentWrap>
          {title && <Title>{title}</Title>}
          {_rawBody && (
            <BasePortableText blocks={_rawBody} serializers={serializers} />
          )}
          {items && (
            <ItemWrap>
              {items.map(item => (
                <Item {...item} />
              ))}
            </ItemWrap>
          )}
          {hasButtons && (
            <Buttons buttons={buttons} alignment={buttonAlignment} />
          )}
        </ContentWrap>
      </Section>
    </OuterWrapper>
  )
}

export default ItemsBlock

ItemsBlock.propTypes = {
  content: PropTypes.object,
  index: PropTypes.number,
}
