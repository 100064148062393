import React from 'react'
import PropTypes from 'prop-types'
import tw from 'twin.macro'

import { FaFacebookSquare, FaTwitterSquare, FaLinkedin } from 'react-icons/fa'

const iconStyle = tw`inline-block p-2 text-black`

const Icon = ({ type, url }) => (
  <a css={[iconStyle]} href={url}>
    {type === 'Facebook' && <FaFacebookSquare size="2rem" />}
    {type === 'Twitter' && <FaTwitterSquare size="2rem" />}
    {type === 'LinkedIn' && <FaLinkedin size="2rem" />}
  </a>
)

export default Icon

Icon.propTypes = {
  type: PropTypes.string,
  url: PropTypes.string,
}
