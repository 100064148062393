import React from 'react'
import PropTypes from 'prop-types'

// import tw from 'twin.macro'
import MenuButton from '../../atoms/MenuButton'
import MenuItems from './Items'

const Menu = ({ items, menuOpen, setMenuOpen }) => (
  <>
    <MenuButton menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
    <MenuItems items={items} menuOpen={menuOpen} />
  </>
)

export default Menu

Menu.propTypes = {
  items: PropTypes.object,
  menuOpen: PropTypes.bool,
  setMenuOpen: PropTypes.func,
}
