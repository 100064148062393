import React from 'react'
// import PropTypes from 'prop-types'
import tw from 'twin.macro'

const Input = tw.input`font-sans block w-full border px-4 py-2 mb-4 border-gray-600 rounded text-black`
const TextArea = tw.textarea`font-sans block w-full border border-gray-600 rounded px-4 py-2 mb-4 text-black`
const Button = tw.button`font-sans px-4 py-2 text-sm font-bold uppercase transition-colors duration-200 ease-in-out bg-white text-black border-2 border-black rounded hover:text-white hover:bg-black inline-flex items-center ml-auto`
const Icon = tw.svg`w-2 ml-4`

const Section = tw.section`md:max-w-screen-sm mx-auto lg:max-w-screen-md mb-8`
function ContactForm() {
  return (
    <Section>
      <form
        name="contact"
        method="post"
        netlify-honeypot="bf"
        data-netlify="true"
        action="/thank-you"
      >
        <input type="hidden" name="form-name" value="contact" />
        <input className="hidden" type="hidden" name="bf" />

        <Input
          id="name"
          name="name"
          type="text"
          placeholder="Name"
          aria-label="Name"
          required
        />

        <Input
          id="email"
          name="email"
          type="email"
          placeholder="E-mail"
          aria-label="E-mail"
          required
        />

        <Input
          id="phone"
          name="phone"
          type="text"
          placeholder="Phone"
          aria-label="Phone"
        />

        <TextArea
          rows="5"
          id="message"
          name="message"
          placeholder="Message"
          aria-label="Message"
          required
        />

        <Button type="submit">
          Send to Ned!{' '}
          <Icon
            aria-hidden="true"
            focusable="false"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
          >
            <g className="fa-group">
              <path
                fill="currentColor"
                d="M188.74 256l56.78 56.89L91.21 466.9a24 24 0 0 1-33.94 0l-22.7-22.65a23.93 23.93 0 0 1 0-33.84z"
              />
              <path
                fill="currentColor"
                d="M91.25 45.06l194.33 194a23.93 23.93 0 0 1 0 33.84l-40 40-211-211.34a23.92 23.92 0 0 1 0-33.84l22.7-22.65a24 24 0 0 1 33.97-.01z"
              />
            </g>
          </Icon>
        </Button>
      </form>
    </Section>
  )
}

export default ContactForm

ContactForm.propTypes = {}
