import React from 'react'
import PropTypes from 'prop-types'
import tw from 'twin.macro'

import Icon from '../atoms/Icon'

const IconWrap = tw.div`-mx-4 py-4 text-center`

function SocialAccounts({ socialAccounts }) {
  return (
    <IconWrap>
      {socialAccounts.map(item => (
        <Icon type={item.network} url={item.url} />
      ))}
    </IconWrap>
  )
}

export default SocialAccounts

SocialAccounts.propTypes = {
  socialAccounts: PropTypes.object,
}
