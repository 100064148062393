import React from 'react'
import tw from 'twin.macro'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

const Paragraph = tw.p`font-sans leading-relaxed mb-4 md:text-lg md:leading-loose`
const Ul = tw.ul`list-disc mb-8`
const Li = tw.li`font-sans ml-8 leading-relaxed md:text-xl md:leading-loose`

//* * Documentation here https://github.com/sanity-io/block-content-to-react */

const serializers = {
  types: {
    block: ({ children }) => <Paragraph>{children}</Paragraph>,
  },
  link: ({ children, mark }) => {
    const internal = /^\/(?!\/)/.test(mark.href)

    if (internal) {
      return (
        <AniLink className="text-blue-800 underline" to={mark.href}>
          {children}
        </AniLink>
      )
    }
    return (
      <a
        href={mark.href}
        className="text-blue-800 underline"
        target="_blank"
        rel="nofollow noopener noreferrer"
      >
        {children}
      </a>
    )
  },
  list: ({ children }) => <Ul>{children}</Ul>,
  listItem: ({ children }) => <Li>{children}</Li>,
}

export default serializers
