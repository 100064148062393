import React from 'react'
import PropTypes from 'prop-types'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import tw from 'twin.macro'

const buttonStyle = tw`px-4 py-2 font-sans text-sm font-bold text-white uppercase transition-colors duration-200 ease-in-out bg-blue-800 border-2 border-white rounded md:text-lg md:px-6 md:py-4 hover:text-white hover:bg-gray-800`

function Button({ button }) {
  return (
    <AniLink css={[buttonStyle]} fade to={button.buttonLink} duration={0.5}>
      {button.buttonTitle}
    </AniLink>
  )
}

export default Button

Button.propTypes = {
  button: PropTypes.object,
}
