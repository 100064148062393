import React from 'react'
import PropTypes from 'prop-types'
import tw from 'twin.macro'

import Button from '../atoms/Button'

const ButtonWrap = tw.article`py-8`

function Buttons({ buttons, alignment }) {
  let alignStyle = null

  if (alignment === 'text-center') {
    alignStyle = 'center'
  }
  if (alignment === 'text-right') {
    alignStyle = 'right'
  }

  return (
    <ButtonWrap
      style={{
        textAlign: alignStyle,
      }}
    >
      {buttons.map(button => (
        <Button button={button} />
      ))}
    </ButtonWrap>
  )
}

export default Buttons

Buttons.propTypes = {
  buttons: PropTypes.object,
}
