import React from 'react'
import PropTypes from 'prop-types'
import tw from 'twin.macro'
import BasePortableText from '@sanity/block-content-to-react'
import { GatsbyImage } from 'gatsby-plugin-image'
import Buttons from '../Buttons'

import serializers from '../Serializers'

import OuterWrapper from './OuterWrapper'
import BlockContactForm from './ContactForm'

const Section = tw.section`md:max-w-screen-md mx-auto lg:max-w-screen-xl`
const ContentWrap = tw.article`px-4 py-8 md:py-12`
const Title = tw.h2`font-heading pb-4 font-bold text-xl md:text-2xl lg:text-4xl`
const TitleWrap = tw.div`md:flex md:justify-between`
const TitleInnerWrap = tw.div``
const Subtitle = tw.h3`font-heading py-4 font-bold text-lg md:text-xl lg:text-2xl md:mb-12`

const LogoWrap = tw.div`block ml-auto text-center mb-4 md:ml-8`

function Content({
  content: { title, subtitle, _rawBody, buttons, buttonAlignment, logo },
  hasContactForm,
  index,
}) {
  let hasButtons = false

  if (buttons) hasButtons = true
  if (buttons === []) hasButtons = false
  if (buttons?.length === 0) hasButtons = false

  return (
    <OuterWrapper index={index}>
      <Section>
        <ContentWrap>
          <TitleWrap>
            <TitleInnerWrap>
              {title && <Title> {title} </Title>}{' '}
              {subtitle && <Subtitle> {subtitle} </Subtitle>}
            </TitleInnerWrap>
            {logo && (
              <LogoWrap>
                <GatsbyImage
                  alt={logo.asset.altText}
                  image={logo.asset.gatsbyImageData}
                  width="100"
                />
              </LogoWrap>
            )}
          </TitleWrap>
          {_rawBody && (
            <div className="portable-text">
              <BasePortableText blocks={_rawBody} serializers={serializers} />
            </div>
          )}
          {hasButtons && (
            <Buttons buttons={buttons} alignment={buttonAlignment} />
          )}
        </ContentWrap>
        {hasContactForm && (
          <ContentWrap>
            <BlockContactForm />
          </ContentWrap>
        )}
      </Section>
    </OuterWrapper>
  )
}

export default Content

Content.propTypes = {
  content: PropTypes.object,
  hasContactForm: PropTypes.bool,
  index: PropTypes.number,
}
