import React from 'react'
// import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import tw from 'twin.macro'

import Item from '../atoms/FooterMenuItem'
import SocialAccounts from './SocialAccounts'

const FooterWrap = tw.footer`text-center relative z-30 p-4 bg-white `
const InlineMenu = tw.div`md:flex justify-center my-12`
const Copyright = tw.p`font-sans text-black pr-1`
const Menu = tw.div`text-black`

const Footer = () => {
  const { settings } = useStaticQuery(graphql`
    query Footer {
      settings: sanitySiteSettings {
        footerCopyright
        footerMenu {
          items {
            _key
            title
            url {
              slug {
                current
              }
            }
          }
        }
        socialAccounts {
          socialAccounts {
            network
            url
          }
        }
      }
    }
  `)

  const {
    footerCopyright,
    footerMenu,
    socialAccounts: { socialAccounts },
  } = settings

  return (
    <>
      <div
        css={[tw`h-12 -mb-px bg-white`]}
        style={{
          clipPath: 'polygon(0 70%, 100% 0, 100% 100%, 0% 100%)',
        }}
      />
      <FooterWrap>
        <SocialAccounts socialAccounts={socialAccounts} />
        <InlineMenu>
          <Copyright>
            {footerCopyright} - Made with{' '}
            <span className="text-red-500">❤</span>&nbsp; in Manchester
          </Copyright>
          <Menu>
            {footerMenu.items.map(item => (
              <Item key={item._key} item={item} />
            ))}
          </Menu>
        </InlineMenu>
      </FooterWrap>
    </>
  )
}

export default Footer

Footer.propTypes = {
  // buttons: PropTypes.object,
}
