import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { gsap } from 'gsap'
import tw from 'twin.macro'

import Item from '../../atoms/MenuItem'

const ItemsWrap = tw.nav`fixed z-40 font-sans w-full h-screen flex flex-col justify-center items-center bg-black text-white`

const itemsVisible = tw`fixed inset-0 flex items-center justify-center w-full h-screen`
const itemsHidden = tw`hidden`

const MenuItems = ({ items, menuOpen }) => {
  const refItemsWrap = useRef()

  useEffect(() => {
    const itemNodes = [...refItemsWrap.current.children]

    if (menuOpen) {
      gsap.to(refItemsWrap.current, {
        height: '100vh',
        duration: 0.5,
        display: 'flex',
      })
      itemNodes.forEach((item, index) => {
        gsap.fromTo(
          item,
          { opacity: 0, x: -30 },
          {
            duration: 0.5,
            delay: 0.2 + index * 0.1,
            opacity: 1,
            x: 0,
          }
        )
      })
    } else {
      itemNodes.forEach(item => {
        gsap.fromTo(
          item,
          { opacity: 1, x: 0 },
          {
            duration: 0.25,
            opacity: 0,
            x: 30,
          }
        )
      })

      gsap.to(refItemsWrap.current, {
        duration: 0.25,
        height: 0,
        delay: 0.5,
        display: 'hidden',
      })
    }
  }, [menuOpen])

  return (
    <ItemsWrap ref={refItemsWrap} css={[menuOpen ? itemsVisible : itemsHidden]}>
      {items.map(item => (
        <Item key={item._key} item={item} />
      ))}
    </ItemsWrap>
  )
}

export default MenuItems

MenuItems.propTypes = {
  items: PropTypes.object,
  menuOpen: PropTypes.func,
}
