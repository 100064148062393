import React from 'react'
import PropTypes from 'prop-types'
import tw from 'twin.macro'

const Wrapper = tw.div`mb-16`

function OuterWrapper({ children, index }) {
  return (
    <Wrapper>
      <div
        css={[
          tw`h-12 -mb-px`,
          index === 0 && tw`text-white bg-black`,
          index === 1 && tw`text-white bg-gray-800`,
          index === 2 && tw`text-white bg-gray-600`,
          index === 3 && tw`text-black bg-gray-400`,
          index === 4 && tw`text-black bg-gray-200`,
          index >= 5 && tw`text-black bg-white`,
        ]}
        style={{
          clipPath: 'polygon(100% 0, 100% 100%, 0 100%, 0 95%, 100% 0)',
        }}
      />
      <div
        css={[
          index === 0 && tw`text-white bg-black`,
          index === 1 && tw`text-white bg-gray-800`,
          index === 2 && tw`text-white bg-gray-600`,
          index === 3 && tw`text-black bg-gray-400`,
          index === 4 && tw`text-black bg-gray-200`,
          index >= 5 && tw`text-black bg-white`,
        ]}
      >
        {children}
      </div>
      <div
        css={[
          tw`h-12 -mt-px`,
          index === 0 && tw`text-white bg-black`,
          index === 1 && tw`text-white bg-gray-800`,
          index === 2 && tw`text-white bg-gray-600`,
          index === 3 && tw`text-black bg-gray-400`,
          index === 4 && tw`text-black bg-gray-200`,
          index >= 5 && tw`text-black bg-white`,
        ]}
        style={{
          clipPath: 'polygon(100% 0, 100% 5%, 0 100%, 0 100%, 0 0)',
        }}
      />
    </Wrapper>
  )
}

export default OuterWrapper

OuterWrapper.propTypes = {
  children: PropTypes.object,
  index: PropTypes.number,
}
