import React from 'react'
import PropTypes from 'prop-types'
import { RoughNotation } from 'react-rough-notation'

import tw from 'twin.macro'

const HeadingContent = tw.p`font-sans py-4 md:p-12 text-white`
const Heading = tw.h1`font-heading font-bold text-4xl p-4 md:text-6xl lg:text-8xl inline-block`
const SubTitle = tw.h2`font-heading font-bold p-4 md:text-2xl`
const Content = tw.p`font-sans p-4`

function Header({ title, headerSubtitle, headerContent }) {
  return (
    <HeadingContent>
      <Heading>
        <RoughNotation type="highlight" show color="#00000075">
          {title}
        </RoughNotation>
      </Heading>

      {headerSubtitle && (
        <SubTitle>
          <RoughNotation
            type="highlight"
            show
            animationDelay="200"
            animationDuration="400"
            color="#00000075"
          >
            {headerSubtitle}
          </RoughNotation>
        </SubTitle>
      )}
      {headerContent && (
        <Content>
          <RoughNotation
            type="highlight"
            show
            animationDelay="400"
            animationDuration="200"
            color="#00000075"
          >
            {headerContent}
          </RoughNotation>
        </Content>
      )}
    </HeadingContent>
  )
}

export default Header

Header.propTypes = {
  title: PropTypes.string,
  headerSubtitle: PropTypes.string,
  headerContent: PropTypes.object,
}
