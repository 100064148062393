import React from 'react'
import PropTypes from 'prop-types'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import tw from 'twin.macro'

const MenuItem = ({ item }) => (
  <AniLink
    css={[tw`block p-4 text-2xl text-center`]}
    fade
    to={
      item.url.slug.current === 'homepage' ? '/' : `/${item.url.slug.current}/`
    }
  >
    {item.title}
  </AniLink>
)

export default MenuItem

MenuItem.propTypes = {
  item: PropTypes.object,
}
