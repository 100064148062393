import React from 'react'
import PropTypes from 'prop-types'
import tw from 'twin.macro'
import BasePortableText from '@sanity/block-content-to-react'
import { GatsbyImage } from 'gatsby-plugin-image'

import { ImQuotesLeft } from 'react-icons/im'

import serializersTestimonial from '../SerializersTestimonial'
import OuterWrapper from './OuterWrapper'

const Section = tw.section`md:max-w-screen-xl md:mx-auto`
const ContentWrap = tw.article`px-4 py-8`
const Title = tw.h2`font-heading py-4 font-bold text-xl md:text-2xl lg:text-4xl`

const TestimonialItem = tw.div`md:flex py-12`
const Name = tw.h3`font-heading font-bold text-xl text-right`
const Company = tw.p`font-sans text-lg text-right`
const ImageWrap = tw.div`text-center pb-12 max-w-xs mx-auto flex-shrink-0 md:mr-12 md:w-1/4`
const TestimonialContent = tw.div``
const PortableTextWrap = tw.div`pt-4 flex`

// const LogoWrap = tw.div`block mx-auto text-center py-12`

function TestimonialsGrid({ content: { title, testimonials }, index }) {
  return (
    <OuterWrapper index={index}>
      <Section>
        <ContentWrap>
          {title && <Title>{title}</Title>}

          {testimonials &&
            testimonials.map(testimonial => (
              <TestimonialItem>
                <ImageWrap>
                  {testimonial.image && (
                    <GatsbyImage
                      alt={testimonial.image.asset.altText}
                      image={testimonial.image.asset.gatsbyImageData}
                    />
                  )}
                </ImageWrap>
                <TestimonialContent>
                  {testimonial._rawTestimonial && (
                    <PortableTextWrap>
                      <ImQuotesLeft
                        css={tw`flex-shrink-0 mr-4 -mt-2`}
                        size="2rem"
                      />
                      <BasePortableText
                        blocks={testimonial._rawTestimonial}
                        serializers={serializersTestimonial}
                      />
                    </PortableTextWrap>
                  )}
                  <Name>{testimonial.name}</Name>
                  {testimonial.companyName && (
                    <Company>{testimonial.companyName}</Company>
                  )}
                </TestimonialContent>
              </TestimonialItem>
            ))}
        </ContentWrap>
      </Section>
    </OuterWrapper>
  )
}

export default TestimonialsGrid

TestimonialsGrid.propTypes = {
  content: PropTypes.object,
  title: PropTypes.string,
  testimonials: PropTypes.object,
  index: PropTypes.number,
}
