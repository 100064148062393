import React from 'react'
import PropTypes from 'prop-types'

import tw from 'twin.macro'

function MenuButton({ menuOpen, setMenuOpen }) {
  const Button = tw.button`fixed right-0 top-0 z-50 m-4`
  const transition = tw`transition-all duration-150 ease-in-out`
  const spanDefault = tw`block my-1 bg-white rounded-full w-menu-width h-menu-height`

  const spanOpenTop = tw`relative block my-1 transform -rotate-45 bg-white rounded-full w-menu-width-open h-menu-height top-3px left-2px`

  const spanOpenMid = tw`block w-0`

  const spanOpenBottom = tw`relative block my-1 transform rotate-45 bg-white rounded-full w-menu-width-open h-menu-height bottom-3px left-2px`

  return (
    <Button type="button" onClick={() => setMenuOpen(!menuOpen)}>
      <span
        css={!menuOpen ? [transition, spanDefault] : [transition, spanOpenTop]}
      />
      <span
        css={!menuOpen ? [transition, spanDefault] : [transition, spanOpenMid]}
      />
      <span
        css={
          !menuOpen ? [transition, spanDefault] : [transition, spanOpenBottom]
        }
      />
    </Button>
  )
}

export default MenuButton

MenuButton.propTypes = {
  menuOpen: PropTypes.bool,
  setMenuOpen: PropTypes.func,
}
