import React from 'react'
import PropTypes from 'prop-types'
import tw from 'twin.macro'
import { GatsbyImage } from 'gatsby-plugin-image'

import OuterWrapper from './OuterWrapper'
import Buttons from '../Buttons'

const Section = tw.section`md:max-w-screen-md mx-auto lg:max-w-screen-xl`
const ContentWrap = tw.article`px-4 py-8 w-full`
const Title = tw.h2`font-heading py-4 font-bold text-xl md:text-2xl lg:text-4xl`

const ImagesWrap = tw.div`text-center pb-12 max-w-screen-lg mx-auto flex flex-wrap`
const Image = tw.div`md:w-64 h-auto inline-block p-4 mx-auto w-full`

// const LogoWrap = tw.div`block mx-auto text-center py-12`

function LogoSlider({
  content: { title, images, buttons, buttonAlignment },
  index,
}) {
  let hasButtons = false

  if (buttons) hasButtons = true
  if (buttons === []) hasButtons = false
  if (buttons?.length === 0) hasButtons = false

  return (
    <OuterWrapper index={index}>
      <Section>
        <ContentWrap>
          {title && <Title>{title}</Title>}

          <ImagesWrap>
            {images &&
              images.map(image => (
                <Image>
                  <GatsbyImage
                    objectFit="contain"
                    css={tw`w-full h-auto md:w-52 `}
                    alt={image.asset.altText}
                    image={image.asset.gatsbyImageData}
                  />
                </Image>
              ))}
          </ImagesWrap>

          {hasButtons && (
            <Buttons buttons={buttons} alignment={buttonAlignment} />
          )}
        </ContentWrap>
      </Section>
    </OuterWrapper>
  )
}

export default LogoSlider

LogoSlider.propTypes = {
  content: PropTypes.object,
  title: PropTypes.string,
  index: PropTypes.number,
}
