import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import tw from 'twin.macro'
import Seo from '../components/SEO'
import Navigation from '../components/Navigation'
import Header from '../components/Header'
import HeaderImage from '../components/HeaderImage'
import Footer from '../components/Footer'
import BlockContent from '../components/Blocks/Content'
import BlockTestimonialsGrid from '../components/Blocks/TestimonialsGrid'
import BlockLogoSlider from '../components/Blocks/LogoSlider'
import BlockItems from '../components/Blocks/Items'

const PageWrap = tw.section`relative pt-screen-4/5 z-10 -mt-screen-1/5`

export const query = graphql`
  query PagePageQuery($id: String!) {
    page: sanityPage(id: { eq: $id }) {
      title
      headerSubtitle
      headerContent {
        _rawChildren
      }
      seoTitle
      seoDescription
      backgroundImage {
        asset {
          gatsbyImageData(
            fit: FILLMAX
            width: 1920
            height: 1080
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      logo {
        asset {
          gatsbyImageData(
            fit: FILLMAX
            width: 500
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      content {
        ... on SanityContent {
          _key
          _type
          title
          subtitle
          _rawBody(resolveReferences: { maxDepth: 10 })
          logo {
            asset {
              gatsbyImageData(
                fit: FILLMAX
                width: 400
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          buttonAlignment
          buttons {
            buttonTitle
            buttonLink
          }
        }
        ... on SanityContactForm {
          _key
          _type
          title
          _rawBody
        }
        ... on SanityItemGrid {
          _key
          _type
          title
          _rawBody
          buttonAlignment
          buttons {
            buttonTitle
            buttonLink
          }
          items {
            title
            subTitle
            description
            buttonUrl
            buttonText
            url
            twitterUrl
            facebookUrl
            instagramUrl
            linkedInUrl
            youtubeUrl
            appStoreUrl
            playStoreUrl
            image {
              asset {
                gatsbyImageData(
                  fit: FILLMAX
                  height: 200
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
        ... on SanityLogoSlider {
          _key
          _type
          title
          buttonAlignment
          buttons {
            buttonTitle
            buttonLink
          }
          images {
            asset {
              gatsbyImageData(
                width: 360
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
        ... on SanityTestimonialsGrid {
          _key
          _type
          title
          testimonials {
            name
            _rawTestimonial
            companyName
            image {
              asset {
                gatsbyImageData(
                  fit: FILLMAX
                  width: 200
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  }
`

const Page = ({ location, data: { page } }) => {
  const {
    title,
    headerSubtitle,
    headerContent,
    seoTitle,
    seoDescription,
    backgroundImage,
    logo,
    content,
  } = page

  const url = `https://www.nedpoulter.com${location.pathname}`

  return (
    <>
      <Seo
        title={seoTitle}
        description={seoDescription}
        backgroundImage={backgroundImage}
        url={url}
      />
      <noscript>
        <iframe
          title="gtm"
          src="https://www.googletagmanager.com/ns.html?id=GTM-W4FZXM"
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />
      </noscript>

      <Navigation />
      <HeaderImage
        title={title}
        backgroundImage={backgroundImage}
        logo={logo}
      />

      <PageWrap>
        <Header
          title={title}
          headerSubtitle={headerSubtitle}
          headerContent={headerContent}
        />

        {content &&
          content.map(contentData => {
            const index = content.findIndex(
              item => item._key === contentData._key
            )

            if (contentData._type === 'content') {
              return <BlockContent content={contentData} index={index} />
            }
            if (contentData._type === 'contactForm') {
              return (
                <>
                  <BlockContent
                    content={contentData}
                    index={index}
                    hasContactForm
                  />
                </>
              )
            }
            if (contentData._type === 'testimonialsGrid') {
              return (
                <BlockTestimonialsGrid content={contentData} index={index} />
              )
            }
            if (contentData._type === 'logoSlider') {
              return <BlockLogoSlider content={contentData} index={index} />
            }
            if (contentData._type === 'itemGrid') {
              return <BlockItems content={contentData} index={index} />
            }
            return null
          })}
      </PageWrap>
      <Footer />
    </>
  )
}

export default Page

Page.propTypes = {
  location: PropTypes.string,
  data: PropTypes.object,
  page: PropTypes.object,
}
